import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import LandingPage from './Landingpage';
import Menupage from './Menupage';
import Confirmation from './Confirmation';

const App = () => (
  <div>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/createyourpage" element={<Menupage />} />
      <Route path="/confirmation" element={<Confirmation/>} />
    </Routes>
  </div>
);

export default App;

