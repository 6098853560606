import React from 'react';
import { Link } from 'react-router-dom';
import './Landingpage.css';
const CompanyLogo = () => {
  return (
    <div className="landing-logo-container">
      <img src="./logo.png" alt="Company Logo" className="landing-logo" />
    </div>
  );
};
const VisitWebpage = () => {
  const handleVisitWebpage = () => {
    window.location.href = 'https://powex.ch';
  };
  return (
    <div className="webpage-button-container">
      <button className="visit-webpage-button" onClick={handleVisitWebpage}>
        Visit Webpage
      </button>
    </div>
  );
};
const LandingPage = () => {
  
  return (
    <div className="landing-page">
      <div className="header">
        <div className="logo-container">
          <CompanyLogo />
        </div>
        <VisitWebpage /> 
      </div>
      <div className="ana">
        
          <div className="landing-form">
            <h1>Welcome to Our Digital Solutions World!</h1>
            <h2>We create stunning websites, mobile apps and open source solutions tailored to your needs.</h2>
            <Link to="/createyourpage" className="get-started-button">
            Get Started
          </Link>
          </div>
        
        <div className="resim">
          <img src="./resimm.gif" alt="landing im" className="resim" />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;