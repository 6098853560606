import React, { useState } from 'react';
import './Menupage.css';
import { useNavigate } from 'react-router-dom';


const CompanyLogo = () => {
    return (
        <div className="menup-logo-container">
            <img src="./logo.png" alt="Company Logo" className="menup-logo" />
        </div>
    );
};

const Menupage = () => {
    const [hosting, setHosting] = useState('');
    const [domain, setDomain] = useState('');
    const [template, setTemplate] = useState('');
    const [extras, setExtras] = useState([]);
    const [textInputValue, setTextInputValue] = useState('');
    const [domainTextInputValue, setDomainTextInputValue] = useState('');
    const [cannotDecideTemplate, setCannotDecideTemplate] = useState(false);
    const [eCommerceOption, setECommerceOption] = useState(null);
    const [wooExtensions, setWooExtensions] = useState([]);
    const [extraPageQuantity, setExtraPageQuantity] = useState(1);
    const [contentCreationQuantity, setContentCreationQuantity] = useState(1);
    const [supportHoursQuantity, setSupportHoursQuantity] = useState(10);
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState(false);
    const [requiredError, setRequiredError] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [wsiteError, setWsiteError] = useState(false);
    const [commentError, setCommentError] = useState(false);
    const [companyError, setCompanyError] = useState(false);


    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        company: '',
        currentWebsite: '',
        comments: '',
    });
  
const nameRegex = /^[a-zA-Z\s]+$/; 
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
const phoneRegex = /^[0-9]+$/; 
const commentsRegex = /^[a-zA-Z0-9\s.,!?()@#$%^&*_-]+$/; 
const companyRegex = /^[a-zA-Z\s]+$/;
const currentWebsiteRegex = /^[a-zA-Z0-9\s.,!?()@#$%^&*_-]+$/;
const domainTextInputRegex = /^[a-zA-Z0-9.-]+$/; 
const textInputRegex = /^[a-zA-Z0-9\s.,!?()@#$%^&*_-]+$/; 

const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
        case 'name':
            if (!nameRegex.test(value)) {
                
                setNameError('Invalid Name format.');
            } else {
                setNameError(false);
            }
            break;
        case 'email':
            if (!emailRegex.test(value)) {
                setMailError('Invalid Email format.');
            } else {
                setMailError(false);
            }
            break;
        case 'phone':
            if (!phoneRegex.test(value)) {
                setPhoneError('Invalid Phone number format.');
            } else {
                setPhoneError(false);
            }
            break;
        case 'comments':
            if (!commentsRegex.test(value)) {
                setCommentError('Invalid Comment format.');
            } else {
                setCommentError(false);
            }
            break;
            case 'currentWebsite':
            if (!currentWebsiteRegex.test(value)) {
                setWsiteError('Invalid URL format.');
            } else {
                setWsiteError(false);
            }
            break;
            case 'company':
            if (!companyRegex.test(value)) {
                setCompanyError('Invalid Text format.');
            } else {
                setCompanyError(false);
            }
            break;
        default:
            
            break;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
};

    const hostingOptions = [
        { name: 'I want to use my hosting service', price: 0, description: 'Please type the name of your hosting service provider if you choose this option.', textInput: true },
        { name: 'I want full hosting service', prices: { 1: 20, 2: 35, 3: 50, 4: 60, 5: 75 }, description: 'Please select the number of years you want to have hosting service.', textInput: false },
        { name: 'Feeling confused? Lets decide together!', price: null, textInput: false },
    ];
    const domainOptions = [
        { name: 'I want to use my domain', price: null, description: 'Please type your existing domain name if you choose this option.', textInput: true },
        { name: 'I want domain', price: 20, description: 'Please type the domain name you want if you choose this option.', textInput: true },
        { name: 'Feeling confused? Lets decide together!', price: null, textInput: false },
    ];
    const [templatePreview, setTemplatePreview] = useState(null);
    const templateOptions = [
        { name: 'Bosa Construction', price: 1120, description: 'This theme has 4 pages. Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/bosa-construction-industrial/1.0.0/screenshot.png?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/bosa-construction-industrial/' },
        { name: 'Building Construction', price: 1120, description: 'This theme has 4 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/building-construction-architecture/0.8/screenshot.jpg?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/building-construction-architecture/' },
        { name: 'Construction Builders', price: 1120, description: 'This theme has 4 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/construction-builders/1.0.0/screenshot.png?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/construction-builders/' },
        { name: 'Creative Construction', price: 1120, description: 'This theme has 4 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/creative-construction/0.2/screenshot.jpg?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/creative-construction/' },
        { name: 'Constructions Agency', price: 1400, description: 'This theme has 5 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/constructions-agency/1.0.9/screenshot.png?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/constructions-agency/' },
        { name: 'Spark Building ', price: 1120, description: 'This theme has 4 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/spark-building-construction/1.0.7/screenshot.png?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/spark-building-construction' },
        { name: 'Construction Bell ', price: 1400, description: 'This theme has 5 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/construction-bell/1.1.2/screenshot.png?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/construction-bell' },
        { name: 'Construction Field', price: 1120, description: 'This theme has 4 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/construction-field/3.0.5/screenshot.jpg?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/construction-field' },
        { name: 'Construction Light', price: 1400, description: 'This theme has 5 pages.Click to see the preview of the theme.', imageUrl: 'https://i0.wp.com/themes.svn.wordpress.org/construction-light/1.6.2/screenshot.png?w=1144&strip=all', wordpressUrl: 'https://wp-themes.com/construction-light' },
        { name: 'Feeling confused? Lets decide together!', price: 0, imageUrl: './mascot.jpeg' },
    ];
    const extrasOptions = [
        { name: 'Whatsapp', price: 10, description: 'Enable whatsapp comunication for your website.', imageUrl: './whsp.png' },
        { name: 'Jitsi', price: 90, description: 'Have your own private conference room with Jitsi online conference solution', imageUrl: './jitsi.gif' },
        { name: 'Extra Page', price: 100, description: 'Add extra page which does not exist on your choosen theme.', imageUrl: './webp.jpg' },
        { name: 'Content Creation', price: 80, description: 'Not sure what to write? Let us create your web page text contents!', imageUrl: './cc.gif' },
        { name: 'Support Hours', description: 'Do you need support? Please select the hours of support you want.', price: 40, imageUrl: './sup.png' },


        { name: 'Feeling confused? Lets decide together!', price: null, imageUrl: './mascot.jpeg' },
    ];
    const eCommerceOptions = [
        { name: 'Woocommerce Bronze', price: 300, description: 'Installation, setup & basic configurations\n2 hours live support\n24 hours reaction time', imageUrl: 'woobr.gif' },
        { name: 'Woocommerce Silver', price: 500, description: 'Installation, setup & basic configurations\n 4 hours live support \n12 hours reaction time', imageUrl: 'woosl.gif' },
        { name: 'Woocommerce Gold', price: 900, description: 'Installation, setup & basic configurations\n4 hours+upto 20 article free+  seo basic\nFastest reaction time 6 hours!', imageUrl: 'woogl.gif' },
        { name: 'Feeling confused? Lets decide together!', description: null, price: null, imageUrl: './mascot.jpeg' },
    ];
    const wooExtensionsOptions = [
        { name: 'Stripe', price: 350, description: 'Accept all major debit and credit cards as well as local payment methods with Stripe.', imageUrl: 'str.png' },
        { name: 'Google Analytics', price: 150, description: 'Do you want to track and receive reports of your website traffic? Understand your customers and increase revenue with worlds leading analytics platform.', imageUrl: './googl.png' },
        { name: 'PayPal', price: 350, description: 'One checkout solution. Many ways to pay. All-in-one solution allows you to offer PayPal, Venmo, Pay Later at no additional cost, credit and debit cards, and country-specific payment options.', imageUrl: 'pp.png' },
        { name: 'HAYVN Pay', price: 350, description: 'Accept cryptocurrency payments online from global customers with HAYVN Pay.', imageUrl: 'hyvn.png' },
        { name: 'Jetpack', price: 150, description: 'Security, performance, and marketing tools made for WooCommerce stores by the WordPress experts. Get started with basic security and speed tools.', imageUrl: 'jp.png' },
        { name: 'LiveChat', price: 150, description: 'Live Chat and messaging platform for sales and support, increase average order value and overall sales through live conversations.', imageUrl: 'lc.png' },
        { name: 'Facebook', price: 150, description: 'Facebook plugin to reach your customers across Facebook, Instagram, Messenger and WhatsApp.', imageUrl: 'fb.png' },
        { name: 'Avalara AvaTax', price: 200, description: 'Automated sales tax calculations for your WooCommerce store.', imageUrl: 'ava.png' },
        { name: 'HubSpot', price: 180, description: 'Sync, automate & analyze data with HubSpot. HubSpot offers a full stack of software for marketing, sales, and customer service, with a completely free CRM at its core.', imageUrl: 'hs.png' },
        { name: 'Feeling confused? Lets decide together!', price: null, imageUrl: './mascot.jpeg' },
    ];
    const selectedHostingPrice = hosting && hosting.name === 'I want full hosting service' && hosting.years
        ? hostingOptions.find((option) => option.name === hosting.name)?.prices[hosting.years] || 0
        : 0;
        
        const handleTextInputChange = (event) => {
            const value = event.target.value;
            if (!textInputRegex.test(value)) {
                setTextInputValue('');
            } else {
                setTextInputValue(value);
            }
        };
    const handleHostingChange = (selectedOption) => {
        if (selectedOption.textInput) {
            setTextInputValue('');
        }
        if (selectedOption.name === 'I want full hosting service') {
            setHosting({ ...selectedOption, years: 1 });
        } else {
            setHosting(selectedOption);
        }
    };
    const handleDomainChange = (selectedOption) => {
        if (selectedOption.name !== 'I want domain' && selectedOption.name !== 'I want to use my domain') {
            setDomainTextInputValue('');
        }
        setDomain(selectedOption);
    };
    const handleDomainTextInputChange = (event) => {
        const value = event.target.value;
        if (!domainTextInputRegex.test(value)) {
            setDomainTextInputValue('');
        } else {
            setDomainTextInputValue(value);
        }
    };
    const handleTemplateChange = (selectedOption) => {
        if (selectedOption.name === 'Feeling confused? Lets decide together!') {
            setCannotDecideTemplate(true);
            setTemplate(null);
            setTemplatePreview(null);
        } else {
            setCannotDecideTemplate(false);
            setTemplate(selectedOption);
            setTemplatePreview(selectedOption.imageUrl);
            const wordpressUrl = selectedOption.wordpressUrl;
            if (wordpressUrl) {
                const iframe = document.getElementById('templatePreviewFrame');
                if (iframe) {
                    iframe.src = wordpressUrl;
                }
            }
        }
    };
    const calculateTotalPrice = () => {
        const hostingPrice = hosting ? hosting.name === 'I want full hosting service' && hosting.years && hostingOptions.find((option) => option.name === hosting.name)?.prices[hosting.years] : 0;
        const domainPrice = domain ? domain.price * (domain.years || 1) : 0;
        const templatePrice = template ? template.price : 0;
        const extrasPrice = extras.reduce((total, extra) => {
            if (extra.name === 'Extra Page') return total + extra.price * extraPageQuantity;
            if (extra.name === 'Content Creation') return total + extra.price * contentCreationQuantity;
            if (extra.name === 'Support Hours') return total + extra.price * supportHoursQuantity;
            return total + extra.price;
        }, 0);

        const eCommercePrice = eCommerceOption ? eCommerceOption.price : 0;
        const extensionPrice = wooExtensions.reduce((total, ext) => total + ext.price, 0);
        return hostingPrice + domainPrice + templatePrice + extrasPrice + eCommercePrice + extensionPrice;
    };


        const handlePreOrderSubmit = () => {
            
            if (
                nameError ||
                mailError ||
                phoneError ||
                commentError ||
                wsiteError ||
                companyError ||
                !hosting ||
                !domain ||
                !template
            ) {
                setIsFormSubmitted(true);
                setRequiredError('Please fill in all required fields with valid data.');
                return;
            }

        const formDataToEmail = {
            ...formData,
            hosting: hosting ? hosting.name : null,
            domain: domain ? domain.name : null,
            template: template ? template.name : null,
            extras: extras.map((extra) => extra.name),
            eCommerceOption: eCommerceOption ? eCommerceOption.name : null,
            wooExtensions: wooExtensions.map((ext) => ext.name),
            totalPrice: calculateTotalPrice(),
        };

        fetch('mailler/mailler.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formDataToEmail),
        })
            .then((response) => {
                if (response.ok) {
                    console.log('Email sent successfully');
                    navigate("/confirmation");
                } else {
                    console.error('Error sending email:', response);
                    setEmailError('Something went wrong. Please try again later.');
                }
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                setEmailError('Something went wrong. Please try again later.');
            });
    };

    return (
        <div className="all-menup">
            <div className="logo-container">
                <CompanyLogo />
            </div>
            <div className="menup">
                <h1 className="titles">Let's Build Your Webpage</h1>
                {/* Hosting Options */}
                <div className="sectiony">
                    <h2 className="sectiony-titles" id={isFormSubmitted && !formData.name ? 'required-field' : ''}>Hosting*</h2>
                    <div className="options-container">
                        {hostingOptions.map((option) => (
                            <div
                                key={option.name}
                                className={`option-item ${hosting && hosting.name === option.name ? 'selected' : ''}`}
                                onClick={() => handleHostingChange(option)}
                            >
                                <label>
                                    <input
                                        type="radio"
                                        name="hosting"
                                        value={option.name}
                                        checked={hosting && hosting.name === option.name}
                                        onChange={() => handleHostingChange(option)}
                                        required
                                    />
                                    <div>
                                        <strong>{option.name}</strong>

                                        {hosting && hosting.name === 'I want full hosting service' && hosting.years && option.prices && option.prices[hosting.years] && `- $${option.prices[hosting.years]}`}
                                    </div>
                                    <p>{option.description}</p>
                                </label>
                                {option.textInput && hosting && hosting.name === option.name && (
                                    <div>
                                        <label>
                                            <strong>Hosting:</strong>
                                            <input className='sec'
                                                type="text"
                                                placeholder="please type"
                                                value={textInputValue}
                                                onChange={handleTextInputChange}
                                                required
                                            />
                                            {textInputValue !== '' && !textInputRegex.test(textInputValue) && <span className="error-message">Invalid input. Only alphanumeric and some special characters allowed.</span>}
                                        </label>
                                    </div>
                                )}
                                {option.name === 'I want full hosting service' && hosting && hosting.name === option.name && (
                                    <div>
                                        <div className="radio-years-container">
                                            {Array.from({ length: 4 }, (_, index) => index + 1).map((year) => (
                                                <label key={year}>
                                                    <input
                                                        type="radio"
                                                        name="hostingYears"
                                                        value={year}
                                                        checked={hosting.years === year}
                                                        onChange={() => setHosting((prev) => ({ ...prev, years: year }))}
                                                        required
                                                    />
                                                    {year} {year === 1 ? 'year' : 'years'}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {option.name === 'Feeling confused? Lets decide together!' && (
                                    <div>
                                        <img src="./mascot.jpeg" alt="Cannot Decide" />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Domain Options */}
                <div className="sectiony">
                    <h2 className="sectiony-titles" id={isFormSubmitted && !formData.name ? 'required-field' : ''}>Domain*</h2>
                    <div className="options-container">
                        {domainOptions.map((option) => (
                            <div
                                key={option.name}
                                className={`option-item ${domain && domain.name === option.name ? 'selected' : ''}`}
                                onClick={() => handleDomainChange(option)}
                            >
                                <label>
                                    <input
                                        type="radio"
                                        name="domain"
                                        value={option.name}
                                        checked={domain && domain.name === option.name}
                                        onChange={() => handleDomainChange(option)}
                                        required
                                    />
                                    <div>
                                        <strong>{option.name}</strong> {option.price !== null && `- $${option.price}`}
                                    </div>
                                    <p>{option.description}</p>
                                </label>
                                {(option.name === 'I want domain' || option.name === 'I want to use my domain') && domain && domain.name === option.name && (
                                    <div>
                                        <label><strong>Domain:</strong>
                                            <input className='sec2'
                                                type="text"
                                                placeholder="Please type"
                                                value={domainTextInputValue}
                                                onChange={handleDomainTextInputChange}
                                                required
                                            />
                                            {domainTextInputValue !== '' && !domainTextInputRegex.test(domainTextInputValue) && <span className="error-message">Invalid input. Only alphanumeric, period, and hyphen allowed.</span>}
                                        </label>

                                    </div>
                                )}
                                {option.name === 'Feeling confused? Lets decide together!' && (
                                    <div>
                                        <img src="./mascot.jpeg" alt="Cannot Decide" />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Template Options */}
                <div className="sectiony">
                    <h2 className="sectiony-titles" id={isFormSubmitted && !formData.name ? 'required-field' : ''}>Themes*</h2>
                    <div className="options-container">
                        {templateOptions.map((option) => (
                            <div
                                key={option.name}
                                className={`option-item ${template && template.name === option.name ? 'selected' : ''}`}
                                onClick={() => handleTemplateChange(option)}
                            >
                                <label>
                                    <input
                                        type="radio"
                                        name="template"
                                        value={option.name}
                                        checked={template && template.name === option.name}
                                        onChange={() => handleTemplateChange(option)}
                                        required
                                    />
                                    {option.name === "Feeling confused? Lets decide together!" ? (
                                        <strong>{option.name}</strong>
                                    ) : (
                                        <strong>{option.name} - ${option.price}</strong>
                                    )}
                                    <img src={option.imageUrl} alt={option.name} />
                                </label>
                                <p className="option-description">{option.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Template Preview */}
                {templatePreview && !cannotDecideTemplate && (
                    <div className="sectiony">
                        <h2 className="sectiony-titles">Theme Preview</h2>
                        <div className="preview-container">
                            {template && template.wordpressUrl && (
                                <iframe
                                    id="templatePreviewFrame"
                                    title="Template Preview"
                                    src={template.wordpressUrl}
                                ></iframe>
                            )}
                        </div>
                    </div>
                )}
                {/* E-commerce Options */}
                <div className="sectiony">
                    <h2 className="sectiony-titles">E-commerce Options</h2>
                    <div className="options-container">
                        {eCommerceOptions.map((option) => (
                            <div
                                key={option.name}
                                className={`option-item ${eCommerceOption && eCommerceOption.name === option.name ? 'selected' : ''}`}
                                onClick={() => setECommerceOption(option)}
                            >
                                <label>
                                    <input
                                        type="radio"
                                        name="eCommerce"
                                        value={option.name}
                                        checked={eCommerceOption && eCommerceOption.name === option.name}
                                        onChange={() => setECommerceOption(option)}
                                    />
                                    <div>
                                        <strong>{option.name}</strong> {option.price !== null && `- $${option.price}`}
                                    </div>
                                    <img src={option.imageUrl} alt={option.name} />
                                </label>

                                {option.description && eCommerceOption && eCommerceOption.name === option.name && (
                                    <ul className="left-aligned-list">
                                        {option.description.split('\n').map((desc, index) => (
                                            <li key={index}>{desc}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Extras Options */}
                <div className="sectiony">
                    <h2 className="sectiony-titles">Extras</h2>
                    <div className="options-container">
                        {extrasOptions.map((option) => (
                            <div
                                key={option.name}
                                className={`option-item ${extras.some((extra) => extra.name === option.name) ? 'selected' : ''}`}
                            >
                                <label>
                                    <input
                                        type="checkbox"

                                        value={option.name}
                                        checked={extras.some((extra) => extra.name === option.name)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {

                                                switch (option.name) {
                                                    case 'Extra Page':
                                                        setExtraPageQuantity(1);
                                                        break;
                                                    case 'Content Creation':
                                                        setContentCreationQuantity(1);
                                                        break;
                                                    case 'Support Hours':
                                                        setSupportHoursQuantity(1);
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                setExtras((prevExtras) => [...prevExtras, option]);
                                            } else {
                                                setExtras((prevExtras) => prevExtras.filter((extra) => extra.name !== option.name));
                                            }
                                        }}
                                    />
                                    <strong>{option.name}</strong> {option.price !== null && `- $${option.price}`}
                                    <img src={option.imageUrl} alt={option.name} />
                                </label>
                                <p className="option-description">{option.description}</p>

                                {extras.some((extra) => extra.name === option.name) && (
                                    <div>
                                        {option.name === 'Extra Page' && (
                                            <label>
                                                <strong>Page Quantity:</strong>
                                                <select className='sec'
                                                    value={extraPageQuantity}
                                                    onChange={(e) => setExtraPageQuantity(Number(e.target.value))}
                                                >

                                                    {[...Array(5).keys()].map((number) => (
                                                        <option key={number} value={number + 1}>
                                                            {number + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                        )}
                                        {option.name === 'Content Creation' && (
                                            <label>
                                                <strong>Page Quantity:</strong>
                                                <select className='sec'
                                                    value={contentCreationQuantity}
                                                    onChange={(e) => setContentCreationQuantity(Number(e.target.value))}
                                                >

                                                    {[...Array(10).keys()].map((number) => (
                                                        <option key={number} value={number + 1}>
                                                            {number + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </label>
                                        )}
                                        {option.name === 'Support Hours' && (
                                            <label>
                                                <strong>Total Hours:</strong>
                                                <select className='sec'
                                                    value={supportHoursQuantity}
                                                    onChange={(e) => setSupportHoursQuantity(Number(e.target.value))}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                </select>
                                            </label>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Woo Extensions */}
                <div className="sectiony">
                    <h2 className="sectiony-titles">Woo Extensions To Enrich Your Online Store</h2>
                    <div className="options-container">
                        {wooExtensionsOptions.map((option) => (
                            <div
                                key={option.name}
                                className={`option-item ${wooExtensions.some((ext) => ext.name === option.name) ? 'selected' : ''}`}
                            >
                                <label>
                                    <input
                                        type="checkbox"
                                        name="extension"
                                        value={option.name}
                                        checked={wooExtensions.some((ext) => ext.name === option.name)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                                setWooExtensions((prevExtensions) => [...prevExtensions, option]);
                                            } else {
                                                setWooExtensions((prevExtensions) =>
                                                    prevExtensions.filter((ext) => ext.name !== option.name)
                                                );
                                            }
                                        }}

                                    />
                                    <div>
                                        <strong>{option.name}</strong> {option.price !== null && `- $${option.price}`}
                                    </div>
                                    <img src={option.imageUrl} alt={option.name} />
                                    <p className='woo-descript'>{option.description}</p>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Form */}
                <div className="sectiony">
                    <h2 className="sectiony-titles">Contact information</h2>
                    <div className="form-container-y" id='contact-heigh'>
                        <label>
                            <strong className={isFormSubmitted && !formData.name ? 'required-field' : ''}>Name Surname:*</strong>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                            {nameError && <span className="error-message">{nameError}</span>}
                        </label>
                        <label>
                            <strong className={isFormSubmitted && !formData.email ? 'required-field' : ''}>Email:*</strong>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            {mailError && <span className="error-message">{mailError}</span>}
                        </label>
                        <label>
                            <strong className={isFormSubmitted && !formData.phone ? 'required-field' : ''}>Phone Number:*</strong>
                            <input
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                required
                            />
                            {phoneError && <span className="error-message">{phoneError}</span>}
                        </label>
                        <label>
                            <strong>Company Name:</strong>
                            <input
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleInputChange}

                            />
                            {companyError && <span className="error-message">{companyError}</span>}
                        </label>
                        <label>
                            <strong>Current Website:</strong>
                            <input
                                type="url"
                                name="currentWebsite"
                                value={formData.currentWebsite}
                                onChange={handleInputChange}

                            />
                            {wsiteError && <span className="error-message">{wsiteError}</span>}
                        </label>
                        <label>
                            <strong>Comments:</strong>
                            <input
                                type="text"
                                name="comments"
                                value={formData.comments}
                                onChange={handleInputChange}
                                required
                            />
                            {commentError && <span className="error-message">{commentError}</span>}
                        </label>
                    </div>
                </div>
                {/* Estimated Price */}
                <div className="estimated-price">
                    <h2 className="sectiony-titles">Estimated Price</h2>
                    {hosting && (
                        <div className={isFormSubmitted && !hosting ? 'required-field' : ''}>
                            <p>
                                <strong>Hosting Preference:</strong> {hosting.name} - ${selectedHostingPrice}
                                {hosting.years && ` (${hosting.years} year${hosting.years > 1 ? 's' : ''})`}
                            </p>
                            {hosting.textInput && (
                                <div>
                                    <p><strong>Hosting Service Provider:</strong> {textInputValue}</p>
                                </div>
                            )}
                        </div>
                    )}
                    {domain && (
                        <div className={isFormSubmitted && !domain ? 'required-field' : ''}>
                            <p>
                                <strong>Domain Preference:</strong> {domain.name} - ${domain.price * (domain.years || 1)}{' '}
                                {domain.years && `(${domain.years} year${domain.years > 1 ? 's' : ''})`}
                            </p>
                            {domain.textInput && (
                                <div>
                                    <p><strong>Domain Name:</strong> {domainTextInputValue}</p>
                                </div>
                            )}
                        </div>
                    )}
                    {template && (
                        <div className={isFormSubmitted && !template ? 'required-field' : ''}>
                            <p><strong>Selected Theme:</strong> {template.name} - ${template.price}</p>
                        </div>
                    )}
                    {extras.map((extra) => (
                        <div key={extra.name}>
                            {extra.name} - $
                            {extra.price *
                                (extra.name === 'Extra Page'
                                    ? extraPageQuantity
                                    : extra.name === 'Content Creation'
                                        ? contentCreationQuantity
                                        : extra.name === 'Support Hours'
                                            ? supportHoursQuantity
                                            : 1)}
                        </div>
                    ))}

                    {eCommerceOption && (
                        <div>
                            <p><strong>E-commerce Selected:</strong> {eCommerceOption.name} - ${eCommerceOption.price}</p>
                        </div>
                    )}
                    {wooExtensions.length > 0 && (
                        <div className="sectiony">
                            <p><strong>Woo Extension Selected:</strong></p>
                            <div className="selected-options-container">
                                {wooExtensions.map((ext) => (
                                    <div key={ext.name}>
                                        <p><strong>{ext.name}</strong> - ${ext.price}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <p className='total-price'><strong>Estimated Total Price:</strong> ${calculateTotalPrice()}</p>
                    <p className="important-note"><strong>Important Note:</strong> Please click the button below to send us your pre-order. If you submit a pre-order, no fee or payment information will be requested from you at this stage. </p>
                    {isFormSubmitted && (!formData.name || !formData.email || !formData.phone || !hosting || !domain || !template) && (
                        <div className="warning">{requiredError}</div>
                    )}
                    {emailError && (
                        <div className="warning">{emailError}</div>
                    )}
                    <button className='continue-button' onClick={handlePreOrderSubmit} >Place Pre-order</button>
                </div>
            </div>
        </div>
    );
};

export default Menupage;

