import React from 'react';
import './Confirmation.css'; 

const Confirmation = () => {
  return (
    <div className="container">
      <div className="columnContainer">
        <div className="column">
          <img
            src="./approv.jpeg"
            alt="approve"
            style={{ width: '100px', height: '100px' }}
          />
        </div>
      </div>
      <div className="containertext">
        <h1>Thank You!</h1>
        <p className='mssge'>Your pre-order request has been submitted successfully. We will contact you as soon as possible.</p>
      </div>
    </div>
  );
};

export default Confirmation;


